/**
 * Import fonts
 */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

import LetterboxForm from './Letterbox/Letterbox';
import CookieConsent from 'react-cookie-consent';
import AppBar from './AppBar'

/**
 * Application Insight settings
 */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=0a322df2-f0ce-453a-9784-77ba452aa6c5;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/;ApplicationId=ece7587b-a095-4e5c-8c0c-3a411e2ab12e',
        // *** If you're adding the Click Analytics plug-in, delete the next line. ***
        extensions: [reactPlugin],
     // *** Add the Click Analytics plug-in. ***
     // extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {        
          [reactPlugin.identifier]: { history: browserHistory }
       // *** Add the Click Analytics plug-in. ***
       // [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});

appInsights.loadAppInsights();
appInsights.trackPageView("Ordbokser");


/*
This is the startup function
*/
function App() 
{
  return Home();
}

/*
This is the home function, redering the main page layout of the "/"" path
*/
function Home()
{
  return (    

    <div>
      
      <AppBar />
      
      <LetterboxForm />

      <CookieConsent
        location="bottom"
        buttonText="Det er OK!"
        cookieName="TrackingConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#ffffff", background: "green", fontSize: "15px" }}
        expires={150}
      >
      Denne nettsiden bruker cookies for å forbedre din opplevelse. <br />Ved å fortsette å bruke nettsiden, samtykker du til vår bruk av cookies og analyseverktøy.{" "}        
      </CookieConsent>

    </div>
  );

}

export default App;
