class Word
{
    constructor(word, letters)
    {
        this.word = word;
        this.letters = letters;
        this.totalWordLength = 0;
        this.totalWordLengthWithSpace = 0;

        this.CalculateWordSize();
    }

    CalculateWordSize()
    {
        var totalWordLength = 0;        

        this.letters.forEach(letter => {
            //Calculate the total length of the word:
            totalWordLength += letter.width;                    
        });
    
        this.totalWordLength = totalWordLength;        
    }
}

class Letter
{
    constructor(letter, startPosition, height)
    {
        const letterWidth = 45;

        this.letter = letter;
        this.startPosition = startPosition;
        this.height = height;
        this.width = letterWidth;

        this.calculatedLetterHeight = Math.floor(letterWidth/1.3) * height;
        this.calculatedMarginBottom = (Math.floor(letterWidth/1.3) * (startPosition));
        this.calculatedAdjustmentForCellarAttic = letterWidth/7; //0 means no adjustment and the high box is twice the size
    }
}

const kvArray = [
    {key: "A", value: new Letter("A", 1, 2)},
    {key: "B", value: new Letter("B", 1, 2)},
    {key: "C", value: new Letter("C", 1, 2)},
    {key: "D", value: new Letter("D", 1, 2)},
    {key: "E", value: new Letter("E", 1, 2)},
    {key: "F", value: new Letter("F", 1, 2)},
    {key: "G", value: new Letter("G", 1, 2)},
    {key: "H", value: new Letter("H", 1, 2)},
    {key: "I", value: new Letter("I", 1, 2)},
    {key: "J", value: new Letter("J", 1, 2)},
    {key: "K", value: new Letter("K", 1, 2)},
    {key: "L", value: new Letter("L", 1, 2)},
    {key: "M", value: new Letter("M", 1, 2)},
    {key: "N", value: new Letter("N", 1, 2)},
    {key: "O", value: new Letter("O", 1, 2)},
    {key: "P", value: new Letter("P", 1, 2)},
    {key: "Q", value: new Letter("Q", 1, 2)},
    {key: "R", value: new Letter("R", 1, 2)},
    {key: "S", value: new Letter("S", 1, 2)},
    {key: "T", value: new Letter("T", 1, 2)},
    {key: "U", value: new Letter("U", 1, 2)},
    {key: "V", value: new Letter("V", 1, 2)},
    {key: "W", value: new Letter("W", 1, 2)},
    {key: "X", value: new Letter("X", 1, 2)},
    {key: "Y", value: new Letter("Y", 1, 2)},
    {key: "Z", value: new Letter("Z", 1, 2)},
    {key: "Æ", value: new Letter("Æ", 1, 2)},
    {key: "Ø", value: new Letter("Ø", 1, 2)},
    {key: "Å", value: new Letter("Å", 1, 2)},

    {key: "a", value: new Letter("a", 1, 1)},
    {key: "b", value: new Letter("b", 1, 2)},
    {key: "c", value: new Letter("c", 1, 1)},
    {key: "d", value: new Letter("d", 1, 2)},
    {key: "e", value: new Letter("e", 1, 1)},
    {key: "f", value: new Letter("f", 1, 2)},
    {key: "g", value: new Letter("g", 0, 2)},
    {key: "h", value: new Letter("h", 1, 2)},
    {key: "i", value: new Letter("i", 1, 1)},
    {key: "j", value: new Letter("j", 0, 2)},
    {key: "k", value: new Letter("k", 1, 2)},
    {key: "l", value: new Letter("l", 1, 2)},
    {key: "m", value: new Letter("m", 1, 1)},
    {key: "n", value: new Letter("n", 1, 1)},
    {key: "o", value: new Letter("o", 1, 1)},
    {key: "p", value: new Letter("p", 0, 2)},
    {key: "q", value: new Letter("q", 0, 2)},
    {key: "r", value: new Letter("r", 1, 1)},
    {key: "s", value: new Letter("s", 1, 1)},
    {key: "t", value: new Letter("t", 1, 2)},
    {key: "u", value: new Letter("u", 1, 1)},
    {key: "v", value: new Letter("v", 1, 1)},
    {key: "w", value: new Letter("w", 1, 2)},
    {key: "x", value: new Letter("x", 1, 2)},
    {key: "y", value: new Letter("y", 0, 2)},
    {key: "z", value: new Letter("z", 1, 1)},
    {key: "æ", value: new Letter("æ", 1, 1)},
    {key: "ø", value: new Letter("ø", 1, 1)},
    {key: "å", value: new Letter("å", 1, 1)}
]

exports.kvArray = kvArray;
exports.Word = Word;
