import './Letterbox.css';

import * as LetterRepo from '../Letterbox/LetterRepository'
import { useState, useRef } from 'react';
import React from 'react'
import { useReactToPrint } from "react-to-print";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';



//Comment
//This is the main function of the whole page
function LetterboxForm()
{
    const [theWords, setWords] = useState([]);
    const [theLetters, setLetters] = useState([]);    
    const [theBoxes, setBoxes] = useState([]);    

    const contentRef = useRef();
    const previewRef = useRef();

    const [previewChecked, setPreviewChecked] = useState(true);

    const reactToPrintFn = useReactToPrint({ contentRef });

    const wordsToProcessRef = useRef();

    const updateOnInput = (event) => {
        var data = wordsToProcessRef.current.value;
        ProcessWords(setWords, setLetters, setBoxes, data);
    }

    const changeShowPreview = (event) => {
        event.stopPropagation();
        
        let checked = event.target.checked;              
        let input = previewRef?.current;

        if(input == null)
            return;

        if(checked)
            input.style.visibility = "visible";
        else
            input.style.visibility = "hidden";

        setPreviewChecked(checked);
    }


    React.useEffect(() => {

        // function handleResize() {                      
        // }
    
        // //Handle resize on startup
        // handleResize();

        // //Handle resize on window change
        // window.addEventListener('resize', handleResize)
    })

    return (
        
        <div className='main'>                       
            <div className="wordInputFrame">
                <div id="left">
                    <TextField
                        fullWidth
                        id="wordsToProcess"
                        label="Ord separert med komma:"
                        multiline
                        rows={2}
                        defaultValue=""
                        variant="outlined"
                        inputRef={wordsToProcessRef}
                        onChange={updateOnInput}                        
                        />
                </div>

                <div id="right">                    
                    <FormControlLabel control={<Switch checked={previewChecked} onChange={changeShowPreview} />} label="Forhåndsvisning" labelPlacement="start" />                    
                    <br />
                    <Button onClick={() => reactToPrintFn()} variant="contained" style={{maxHeight: '40px'}}>Skriv ut</Button>
                </div>
            </div>
            
            <CreateOuterDiv theWords={theWords} theLetters={theLetters} contentRef={contentRef} previewRef={previewRef} theBoxes={theBoxes} />
                    
         </div>
    );
}

function CreateOuterDiv(props)
{  
    return (
        <div className="outerBorderDiv" ref={props.previewRef} style={{visibility: 'visible', marginTop: '25px'}}>                    
            <div className="shadowDiv"> 
                <div className="outputDiv">
                    
                    <div className="renderedWords" id="renderedWords" ref={props.contentRef}>
                        
                        <RenderWords words={props.theWords} letters={props.theLetters} boxes={props.theBoxes} />                            
                        
                        <div className='page-footer'>
                            <div style={{fontSize: 'larger'}}>Vil du lage ordbokser? Besøk https://letterboxes.brathe.com</div>
                            <div>&copy; 2024 - BPB AS - post@brathe.com</div>
                        </div>
                    </div>                    
                    
                </div>
            </div>
        </div>
    );
}

function RenderWords(props)
{
    var wordObjects = props.words;
    var theBoxes = props.boxes;
    var theWords = wordObjects.map(word => word.word);

    if (wordObjects.length > 0)
    {
        //Get the width of the area to print within
        //var displayAreaWidth = document.getElementById("renderedWords")?.getBoundingClientRect()?.width;
        
        // if(displayAreaWidth <= 0)
        // {
        //     //This will cause issues - skip!
        //     return;
        // }    
        
        // //Figure out if any of the words fit on the same line or not...
        // var boxes = ReorderWords(wordObjects, displayAreaWidth);       
        var boxes = theBoxes;

        return (
            <div className='submittedWords'>  
                <div style={{textAlign:'left', marginLeft: '20px', marginRight: '20px'}}>
                    
                    <div className='boxRow' style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '15px'}}>
                        <div className='boxColumn' style={{width: '70%', display: 'flex' }}>
                            <div style={{marginRight: '10px' }}>Navn:</div>
                            <div style={{borderBottom: '1px solid black', width:'100%'}}>&nbsp;</div>                            
                        </div>
                        <div className='boxColumn' style={{width: '30%', display: 'flex', marginLeft:'10px' }}>
                            <div style={{marginRight: '10px' }}>Dato:</div>
                            <div style={{borderBottom: '1px solid black', width:'100%'}}>&nbsp;</div>
                        </div>                        
                    </div>                  

                    <div style={{border: '1px dashed darkgray', padding: '8px', fontSize: 'large'}}>
                        <ul>
                            {theWords.map((word, index) => (                    
                                <li key={index}>
                                    {word}
                                </li>
                                
                            ))}
                        </ul>
                    </div>
                </div>
                    
                <div style={{marginBottom: 70 + 'px'}}>

                </div>

                <div style={{textAlign:'center'}}>
                    {
                        boxes.map((box, boxIndex) => (
                            GetBoxDiv(box, boxIndex)
                        ))
                    }
                </div>
            </div>
        );
    }
    else
    {
        return (     
            <div></div>       
        );
    }
}

function GetBoxDiv(boxElement, boxIndex)
{
    return (
        <div style={{marginBottom: 15 + 'px'}} key={"wordBox_" + boxIndex} className='boxRow'>
            {boxElement.map((word, wordIndex) => (
                GetWordDiv(word, wordIndex, boxIndex)
            ))}
        </div>
    )
}

function GetWordDiv(wordElement, wordIndex, boxIndex)
{
    return (
        <div style={{marginBottom: '15px'}} className='boxColumn' key={"wordBox_" + boxIndex + "_word_" + wordIndex}>
            {wordElement.letters.map((letterElement, letterElementIndex) => (
                GetSpan(letterElement, wordIndex, letterElementIndex)
            ))}
        </div>
    )
}

function GetSpan(letterElement, wordIndex, letterElementIndex)
{
    var letterHeight = letterElement.calculatedLetterHeight;
    var marginBottom = letterElement.calculatedMarginBottom;
    var adjustmentForCellarAndAttic = letterElement.calculatedAdjustmentForCellarAttic;
    var letterWidth = letterElement.width;

    if(letterElement.startPosition === 1 && letterElement.height > 1)
        letterHeight -= adjustmentForCellarAndAttic;
    else if(letterElement.startPosition === 0 && letterElement.height > 1)
    {
        letterHeight -= adjustmentForCellarAndAttic;
        marginBottom += adjustmentForCellarAndAttic;
    }

    return (
        <span key={"spanKey_" + wordIndex + "_" + letterElementIndex} style={{height: letterHeight + 'px', width: letterWidth + 'px', border: '1px solid black', display: 'inline-block', verticalAlign: 'bottom', marginBottom: marginBottom + 'px'}}>            
        </span>
    )
}

function ProcessWords(setWords, setLetters, setBoxes, words)
{
    var outputWords = [];
    
    if(words.length === 0)
    {
        setWords([]);
        setLetters([]);
        return;
    }
    
    var wordList = words.split(",").filter(word => word.trim() !== '');
    wordList.forEach(word => 
    {        
        //Break it down to letters   
        var letters = Array.from(word);
        var outputLetters = [];

        letters.forEach(letter => 
        {
            //Find the letter in the letter array
            //Add the letter to the word
            var foundLetter = LetterRepo.kvArray.find(kv => kv.key === letter);
            if (foundLetter)
            {   
                //Add the letter to the word                      
                outputLetters.push(foundLetter.value);
            }
        });

        var outputWord = new LetterRepo.Word(word, outputLetters);     
        outputWords.push(outputWord);        
    });

    //var theWords = outputWords.map(word => word.word);
    var theWords = outputWords;
    var theLetters = outputWords.map(word => word.letters);

    var displayAreaWidth = document.getElementById("renderedWords")?.getBoundingClientRect()?.width;
    var boxes = ReorderWords(theWords, displayAreaWidth); 

    setBoxes(boxes);
    setWords(theWords);
    setLetters(theLetters);
}

function ReorderWords(words, displayAreaWidth)
{
    return FitItemsIntoBoxes(words, displayAreaWidth);
}

function FitItemsIntoBoxes(items, boxLength)
{
    const space = 25;
    const maxPerBox = 3;

    // Sort items by their lengths in ascending order
    items.sort((a, b) => a.totalWordLength - b.totalWordLength);

    const boxes = [];
    
    // Recursive function to fill each box optimally
    function fillBox(remainingItems, currentBox, currentLength) {
        // Try to fill the current box with remaining items
        let newItems = [];
        let itemsInBox = 0;

        for (let i = 0; i < remainingItems.length; i++) {
            const item = remainingItems[i];
            const itemLength = item.totalWordLength + space;

            // If adding this item does not exceed the box length
            if (currentLength + itemLength <= boxLength && itemsInBox < maxPerBox) {
                currentBox.push(item);
                currentLength += itemLength;
                itemsInBox++;
            } else {
                // Keep track of items that didn't fit
                newItems.push(item);
            }
        }
        
        // Once the box is filled, save it and process the remaining items
        currentBox.sort((a, b) => 0.5 - Math.random());
        boxes.push(currentBox);
        
        // If there are items left, fill the next box
        if (newItems.length > 0) {        
            fillBox(newItems, [], 0);
        }
    }
    
    // Start filling the first box
    fillBox(items, [], 0);

    //Randomize boxes:
    boxes.sort((a, b) => 0.5 - Math.random());
    return boxes;
}

export default LetterboxForm;